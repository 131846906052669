
import { ArrowDown } from '@element-plus/icons'
import { defineComponent, computed, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
export default defineComponent({
    components: {
        ArrowDown
    },
    setup() {
        const { state, commit } = useStore()
        const router = useRouter()
        let sessionName = sessionStorage.getItem('userAddId')
        const instance: any = getCurrentInstance()
        commit('updateName', sessionName)
        let name = computed(() => {
            return state.name
        })

        function logout() {
            instance.proxy.$http.post('/api/v2.0/logout').then((res: any) => {
                let error = res.error
                if (error == 0) {
                    ElMessage.success('登出成功')
                    sessionStorage.clear();
                    router.push({
                        name: 'Login',
                    })
                }
            })

        }
        function toUpdate() {
            router.push({
                name: 'updatePassword',
            })
        }
        return {
            logout,
            name,
            toUpdate

        }
    }
})
