<template>
    <div class="mains">
      <div>
          <el-menu  :default-active="index" router>
              <el-menu-item-group   v-for="item in menus[userType]" :key="item.index">
                  <el-menu-item :index="item.index" @click="toUrl(item)">{{item.name}}</el-menu-item>
              </el-menu-item-group>
          </el-menu>
      </div>
      
    </div>
  </template>
  
  <script>
  import { defineComponent,ref } from 'vue'
  import {useRouter} from 'vue-router'
  export default defineComponent({
      setup(){
          const menus={
          admin:[
                  {
                      name: '用户管理',
                      url: '/homePage',
                      index:'/homePage'
                  },
                  {
                      name: 'APP运行日志管理',
                      url: '/appLogManage',
                      index:'/appLogManage'
                  },

              ],
      }
          const router=useRouter()
          console.log(router)
          const userType = sessionStorage.getItem('userType')||'admin'
          let key =sessionStorage.getItem('rowindex')
          let index =ref('/homePage')
          if(key){
              index.value=key
          }
          function toUrl(e){
              index.value=e.index
              sessionStorage.setItem('rowindex',e.index)
              sessionStorage.setItem('currIdx',e.url)
              router.push(e.url)
          }
          return{
              menus,
              toUrl,
              userType,
              router,
              index
          }
      }
  })
  </script>
  
  <style scoped >
  .mains{
      display: flex;
      flex-flow: column;
      width: 100%;
     
  }
  .mains .el-menu {
    background: rgb(255, 255, 252);
    color: rgb(177, 175, 186);
  }

  .el-menu-item{
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #888888;
  }

  .el-menu-item.is-active{
    background: rgb(236, 237, 246);
   
    
  }
  >>> .el-menu-item-group__title{
    padding: 0px;
  }
  </style>