import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path:'/',
    redirect: '/login'
  },
  {
    path:'/login',
    name:'Login',
    component:() =>import('../views/Login.vue')
  },
  {
    path:'/Home',
    name:'Home',
    component: Home,
    children:[
      {
        path:'/homePage',
        name:'homePage',
        component:() =>import('../views/homePage.vue')
      },
      {
        path:'/records',
        name:'records',
        component:() =>import('../views/records.vue')
      },
      {
        path:'/status',
        name:'status',
        component:() =>import('../views/status.vue')
      },
      {
        path:'/appLogManage',
        name:'appLogManage',
        component:() =>import('../views/AppLog/appLogManage.vue')
      }
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
